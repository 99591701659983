// DateRangeSelector.js
import React, { useState, useRef, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight, FiCalendar } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DateRangeSelector = ({ onRangeChange }) => {
  const [viewMode, setViewMode] = useState('1day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };
    //set default date range
    const range = getDateRange(currentDate, viewMode);
  

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const formatDate = (date) => {
    return date?.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  const getDateRange = (date, mode) => {
    const start = new Date(date);
    const end = new Date(date);
    
    switch (mode) {
      case '1day':
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        return {
          start,
          end,
          display: formatDate(start)
        };
        
      case 'week':
        const firstDay = date.getDate() - date.getDay();
        start.setDate(firstDay);
        start.setHours(0, 0, 0, 0);
        
        end.setDate(firstDay + 6);
        end.setHours(23, 59, 59, 999);
        return {
          start,
          end,
          display: `${formatDate(start)} - ${formatDate(end)}`
        };
        
      case 'month':
        start.setDate(1);
        start.setHours(0, 0, 0, 0);
        
        end.setMonth(start.getMonth() + 1);
        end.setDate(0);
        end.setHours(23, 59, 59, 999);
        return {
          start,
          end,
          display: start?.toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })
        };

      case 'custom':
        return {
          start: dateRange[0],
          end: dateRange[1],
          display: `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`
        };
        
      default:
        return { start, end, display: formatDate(start) };
    }
  };

  const handleNavigate = (direction) => {
    const newDate = new Date(currentDate);
    
    switch (viewMode) {
      case '1day':
        newDate.setDate(newDate.getDate() + (direction === 'next' ? 1 : -1));
        break;
      case 'week':
        newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
        break;
      case 'month':
        newDate.setMonth(newDate.getMonth() + (direction === 'next' ? 1 : -1));
        break;
    }

    const range = getDateRange(newDate, viewMode);
    setCurrentDate(newDate);
    onRangeChange(range.start, range.end);
  };

  const handleViewModeChange = (mode) => {
    if (mode === viewMode) return;
    
    let newDate = currentDate;
    if (mode === 'month') {
      newDate = new Date(currentDate);
      newDate.setDate(1);
      setCurrentDate(newDate);
    }
    setViewMode(mode);
    
    const range = getDateRange(newDate, mode);
    onRangeChange(range.start, range.end);
  };

  const handleDateRangeSelect = (update) => {
    setDateRange(update);
    if (update[0] && update[1]) {
      setViewMode('custom');
      const start = new Date(update[0]);
      const end = new Date(update[1]);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      onRangeChange(start, end);
      setIsCalendarOpen(false);
    }
  };

  const currentRange = viewMode === 'custom' && dateRange[0] && dateRange[1] 
    ? getDateRange(currentDate, 'custom')
    : getDateRange(currentDate, viewMode);

  return (
    <div className="relative" ref={calendarRef}>
      <div className="flex items-center space-x-2 bg-white rounded-lg border p-1">
        <div className="flex items-center space-x-1 px-2">
          <button
            onClick={() => handleNavigate('prev')}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <FiChevronLeft className="w-4 h-4 text-gray-600" />
          </button>
          <div 
            className="flex items-center space-x-2 cursor-pointer hover:bg-gray-50 rounded-md px-2 py-1"
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
          >
            <FiCalendar className="w-4 h-4 text-golden-text" />
            <span className="text-sm font-medium text-gray-700 min-w-[120px]">
              {currentRange.display}
            </span>
          </div>
          <button
            onClick={() => handleNavigate('next')}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <FiChevronRight className="w-4 h-4 text-gray-600" />
          </button>
        </div>
        <div className="border-l pl-2 flex space-x-1">
          {['1day', 'week', 'month'].map((mode) => (
            <button
              key={mode}
              onClick={() => handleViewModeChange(mode)}
              className={`px-3 py-1 text-xs rounded-md transition-colors ${
                viewMode === mode
                  ? 'bg-golden-light text-golden-text'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              {mode.charAt(0).toUpperCase() + mode.slice(1)}
            </button>
          ))}
        </div>
      </div>

      {isCalendarOpen && (
        <div className="absolute z-50 mt-2">
          <DatePicker
            selected={dateRange[0]}
            onChange={handleDateRangeSelect}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            selectsRange
            inline
            monthsShown={2}
            calendarClassName="bg-white shadow-lg rounded-lg border border-gray-200"
          />
        </div>
      )}

      <style>{`
        .react-datepicker {
          font-family: inherit;
        }
        .react-datepicker__header {
          background-color: #f8fafc;
          border-bottom: 1px solid #e5e7eb;
        }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-range {
          background-color: #1E3A8A;
          color: white;
        }
        .react-datepicker__day--in-selecting-range {
          background-color: rgba(30, 58, 138, 0.5);
        }
        .react-datepicker__day--keyboard-selected {
          background-color: rgba(30, 58, 138, 0.5);
        }
        .react-datepicker__day:hover {
          background-color: #e5e7eb;
        }
      `}</style>
    </div>
  );
};

export default DateRangeSelector;