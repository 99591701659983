import React, { useRef, useEffect } from 'react';
import { FiX } from 'react-icons/fi';

const OrderDetails = ({ order, onClose, products, packs }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      // Check if click is outside modal content
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add click event listener
    document.addEventListener('mousedown', handleClick);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onClose]);

  const formatOrderId = (id) => {
    return `GGO-${id.toString().padStart(7, '0')}`;
  };

  const formatAddress = () => {
    const parts = [
      order.delivery_address,
      order.apartment_number && `Apt ${order.apartment_number}`,
      order.delivery_city,
      order.delivery_zip_code
    ].filter(Boolean);
    return parts.join(', ');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-golden-navy text-white p-2 flex justify-between items-center">
          <h2 className="text-xl font-bold">{formatOrderId(order.id)}</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white hover:bg-opacity-10 rounded-full transition-colors"
          >
            <FiX className="text-xl" />
          </button>
        </div>

        <div className="p-2">
          {/* Property Details */}
          <div className="mb-2">
            <div className="flex items-start">
              <div className="bg-golden-blue bg-opacity-20 p-2 rounded-full mr-3">
                <img 
                  src={order.property?.property_type_id === 1 ? '/ico/propertyHouseIco.svg' : '/ico/propertyBuildingIco.svg'} 
                  alt="Property Type Icon" 
                  className="h-12 w-12"
                />
              </div>
              <div>
                <h3 className="font-semibold text-lg text-golden-text">
                  {order.property_details[0]?.property_title || 'Property Name'}
                </h3>
                <p className="text-sm text-gray-600">{formatAddress()}</p>
              </div>
            </div>
          </div>

          {/* Order Details */}
          <div className="grid grid-cols-2 gap-2 mb-2">
            <div className="bg-gray-50 p-2 rounded-lg">
              <h4 className="font-medium text-sm text-gray-600 mb-1">Date & Time</h4>
              <p className="text-golden-text">
                {order.property_date}    
               <span className='ml-6 float-right'> {order.property_details[0]?.arrival_time} - {order.property_details[0]?.completion_time}</span>
              </p>
            </div>
            <div className="bg-gray-50 p-2 rounded-lg">
              <h4 className="font-medium text-sm text-gray-600 mb-1">Total Amount</h4>
              <p  className="ml-6 float-right text-golden-text font-bold">£{order.order_total}</p>
            </div>
          </div>

          {/* Order Items */}
          <div>
            <h3 className="font-semibold mb-0 text-golden-text">Order Items</h3>
            {order.processed_items && Object.entries(order.processed_items).map(([key, items]) => (
              <div key={key} className="space-y-1">
                {items.map((item, index) => (
                  <div key={index} className="flex items-center p-0 bg-gray-50 rounded-lg">
                    <img 
                      src={products.find(p => p.id == item.order_products_product_id)?.image || 
                           packs.find(p => p.id == item.order_products_product_id)?.image || 
                           '/placeholder-image.jpg'} 
                      alt={item.order_products_name} 
                      className="w-8 h-8 object-cover rounded-lg mr-4"
                    />
                    <div className="flex-grow">
                      <h5 className="font-small">{item.order_products_name}</h5>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">£{item.order_products_price}</p>
                      <p className="text-sm text-gray-600">Qty: {item.order_products_qty}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;