import React, { useEffect, useState } from "react";
import {
  FiSearch,
  FiPlusCircle,
  FiChevronDown,
  FiMinusCircle,
  FiGrid,
  FiList,
} from "react-icons/fi";
import { FaHome, FaTag, FaShoppingCart } from "react-icons/fa";
import DashboardCard from "./DashboardCard";
import PropertyCard from "./PropertyCard";
import PropertyTable from "./PropertyTable";
import cartService from "../services/cartService";
import { notification } from "antd";
import NewPropertyForm from "./properties/NewPropertyForm";
import propertiesService from "../services/propertiesService";
import EditPropertyForm from "./properties/EditPropertyForm";

const Properties = ({ properties, data, orders, refreshProperties,setCartItems }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("default");
  const [showNewPropertyForm, setShowNewPropertyForm] = useState(false); 
  const [editingPropertyId, setEditingPropertyId] = useState(null);
  const [viewMode, setViewMode] = useState("grid"); // New state for view mode
  const [cart, setCart] = useState([])
  
  useEffect(() => {
    setCart(data?.cart)
  }, [data?.cart])

  
  const filteredProperties = properties.filter(
    (property) =>
      property?.property_title
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      property?.apartment_address
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      property?.postcode?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleViewMode = () => {
    setViewMode(viewMode === "grid" ? "table" : "grid");
  };

  const handleEdit = (propertyId) => {
    setEditingPropertyId(propertyId);
  };

  const handleCancelEdit = () => {
    setEditingPropertyId(null);
  };

  const handleUpdateProperty = async (updatedProperty) => {
    notification.success({
      message: "Successful",
      description: `Property updated successfully!`,
    });
    setEditingPropertyId(null);
    refreshProperties();
  };

  const removeFromCart = async (id, property_id) => {
    try {
      let response = await cartService.removeFromCart(id, property_id);
      if (response) {
        notification.success({
          message: "Successful",
          description: `${response.message}!`,
        });
        
        if (cart) {
          // Since cart is an object, we need to use object methods to filter
          const updatedCart = { ...cart };
          delete updatedCart[property_id]; // Remove the property using its ID
          
          setCart(updatedCart);
          setCartItems(updatedCart); // Update cartItems as well if needed
        }
        
        refreshProperties();
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to remove item from cart",
      });
    }
  };
  const handleSaveProperty = async (formData) => {
    // Handle saving the property
    console.log("Saving property:", formData);
    // Make API call to save property
    // Then refresh the properties list
    let response = await propertiesService.createProperty(formData);
    if (response.status) {
      notification.success({
        message: "Successful",
        description: `${response.message}!`,
      });
      refreshProperties();
      setShowNewPropertyForm(false);
    } else {
      notification.error({
        message: "Failed to save property!",
        description: response?.message,
      });
    }
  };
  const handleSaveAndAddToCart = async (formData) => {
    formData.type = 2;
    console.log("Saving property:", formData);
    // Make API call to save property
    // Then refresh the properties list
    let response = await propertiesService.createProperty(formData);
    if (response.status) {
      notification.success({
        message: "Successful",
        description: `${response.message}!`,
      });
      //refreshProperties();
      // setShowNewPropertyForm(false);
    } else {
      notification.error({
        message: "Failed to save property!",
        description: response?.message,
      });
    }
  };
  const addToFromCart = async (property_id) => {
    let response = await cartService.addToCart(property_id);
    if (response) {
      notification.success({
        message: "Successful",
        description: `${response.message}!`,
      });
      setCart(response.cart)
      setCartItems(response.cart)
      refreshProperties();
    }
  };
  const handleToggleStatus = async (propertyId, newStatus) => {
    try {
      // Call your API to update the property status
      const response = await propertiesService.updatePropertyStatus(propertyId, newStatus);
      if (response) {
        notification.success({
          message: "Status Updated",
          description: newStatus === 1 ? "The property successful Activated." : "The property successfully Inactived.",
        });
        refreshProperties();
      } else {
        notification.error({
          message: "Update Failed",
          description: response.message || "Failed to update property status.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while updating property status.",
      });
    }
  };
  const sortedProperties = [...filteredProperties].sort((a, b) => {
    switch (sortBy) {
      case "idAsc":
        return a.id > b.id ? 1 : -1;
      case "idDesc":
        return b.id > a.id ? 1 : -1;
      case "nameAsc":
        return a.property_title.localeCompare(b.property_title);
      case "nameDesc":
        return b.property_title.localeCompare(a.property_title);
      case "addressAsc":
        return a.apartment_address.localeCompare(b.apartment_address);
      case "addressDesc":
        return b.apartment_address.localeCompare(a.apartment_address);
      default:
        return 0;
    }
  });

  return (
    <div className="p-6">
   {/*    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <DashboardCard
          icon={FaHome}
          title="My Properties"
          primaryCount={properties?.length}
          primaryLabel="Total Active Properties"
          bgColor="bg-golden-blue"
        />
        <DashboardCard
          icon={FaTag}
          title="Today Orders"
          primaryCount={orders?.orders_all?.total}
          primaryLabel="Total Active Orders"
        />
        <DashboardCard
          icon={FaShoppingCart}
          title="Shopping Cart"
          primaryCount={data?.cart ? Object.values(data?.cart).length : 0}
          primaryLabel="Total Items in cart"
        />
      </div> */}
    {/*  <div className="bg-golden-yellow text-white p-4 rounded-lg mb-6">
        <p>
          <img
            src="/ico/headphones.svg"
            alt="My Properties Icon"
            className="inline-block mr-2 h-8"
          />
          Contact us for assistance via WhatsApp or email to{" "}
          <span className="text-golden-navy">info@goldengroup.uk</span> for
          prompt support.
        </p>
      </div> */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-golden-navy flex items-center">
          <img
            src="/ico/MyProperties.svg"
            alt="My Properties Icon"
            className="inline-block mr-2 h-8"
          />
          {showNewPropertyForm
            ? "New Property Info"
            : `My Properties (${sortedProperties?.length})`}
        </h2>
        <div className="flex space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search Property"
              className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-golden-blue focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FiSearch className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>

        {/*   <div className="relative">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="default">Sort By</option>
              <option value="idAsc">ID Ascending</option>
              <option value="idDesc">ID Descending</option>
              <option value="nameAsc">Name Ascending</option>
              <option value="nameDesc">Name Descending</option>
              <option value="addressAsc">Address Ascending</option>
              <option value="addressDesc">Address Descending</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FiChevronDown className="h-4 w-4" />
            </div>
          </div> */}
          <button
            className="flex items-center bg-golden-navy text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-300"
            onClick={toggleViewMode}
          >
            {viewMode === "grid" ? (
              <><FiList className="h-5 w-5 mr-2" /></>
            ) : (
              <><FiGrid className="h-5 w-5 mr-2" /></>
            )}
          </button>
          <button
            className="flex items-center bg-golden-navy text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-300"
            onClick={() => {
              if (editingPropertyId) {
                setEditingPropertyId(null);
              }else{
                setShowNewPropertyForm(!showNewPropertyForm)}

            }
            }
          >
            {!showNewPropertyForm ? (
              <FiPlusCircle className="h-5 w-5 mr-2" />
            ) : (
              <FiMinusCircle className="h-5 w-5 mr-2" />
            )}
            {(showNewPropertyForm||editingPropertyId) ? "Cancel" : "Add New Property"}
          </button>
        </div>
      </div>
      {showNewPropertyForm ? (
        <NewPropertyForm
          onSave={handleSaveProperty}
          onSaveAndAddToCart={handleSaveAndAddToCart}
        />
      ) : editingPropertyId ? (
        <EditPropertyForm
          propertyId={editingPropertyId}
          onSave={handleUpdateProperty}
          onCancel={handleCancelEdit}
        />
      ) : (
        <>
            {viewMode === "grid" ? (
        <div
          style={{ maxHeight: "75vh" }}
          className="rounded-lg overflow-y-auto px-3 pt-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-2"
        >
          {sortedProperties.map((property) => (
            <PropertyCard
              key={property.id}
              property={property}
              cart={cart}
              addToFromCart={addToFromCart}
              removeFromCart={removeFromCart}
              onEdit={handleEdit}
              onToggleStatus={handleToggleStatus}
            />
          ))}
        </div>
      ) : (
            <PropertyTable
              properties={sortedProperties}
              cart={cart}
              addToFromCart={addToFromCart}
              removeFromCart={removeFromCart}
              onEdit={handleEdit}
              onToggleStatus={handleToggleStatus}

            />
          )}
          {sortedProperties?.length === 0 && (
            <div className="text-center text-gray-500 mt-6">
              No properties found matching your search.
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Properties;
