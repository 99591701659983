import React, { useEffect } from 'react';
import { Home, Calendar, PenSquare, Trash2 } from 'lucide-react';

const CartItem = ({ item, propertyDetails }) => (
  <div className="bg-white rounded-2xl border border-golden-100 p-6 mb-4">
    <div className="flex justify-between items-start">
      <div className="flex items-start space-x-3">
        <Home className="text-golden-500 h-5 w-5 mt-1" />
        <div>
          <h2 className="text-xl font-semibold">{propertyDetails?.property_title || item.id}</h2>
          <p style={{minHeight:'24px',fontSize:'10px'}} className="text-sm text-gray-500">{propertyDetails?.apartment_address} {propertyDetails?.apartment_number},{propertyDetails?.delivery_zip_code} {propertyDetails?.delivery_city} </p>
          </div>
      </div>
      <div className="flex items-center space-x-4">
        <span className="text-gray-500">
          {new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </span>
        <div className="flex space-x-2">
          <Calendar className="h-5 w-5 text-golden-500" />
          <PenSquare className="h-5 w-5 text-golden-500" />
          <Trash2 className="h-5 w-5 text-golden-500" />
        </div>
      </div>
    </div>
    
    <div className="mt-6">
      {Object.entries(item.items || {}).map(([itemId, itemDetails]) => (
        <div key={itemId} className="flex justify-between items-center py-2">
          <div className="flex items-center">
            <span className="text-gray-700">{itemDetails.name}</span>
            <span className="text-gray-500 ml-4">x{itemDetails.quantity}</span>
          </div>
          <span className="font-medium">£{(parseFloat(itemDetails.price) * itemDetails.quantity).toFixed(2)}</span>
        </div>
      ))}
    </div>
    
    <div className="mt-4 text-gray-500">
      {item.timeSlot || '03:00 - 04:00'}
    </div>
  </div>
);

const CartTotal = ({ subtotal, total }) => (
  <div className="bg-white rounded-2xl shadow-lg p-6">
    <h2 className="text-2xl font-bold mb-6">Cart Total</h2>
    
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Subtotal</span>
        <div>
          <span className="text-red-500 line-through mr-2">£0.00</span>
          <span className="font-bold">£{subtotal.toFixed(2)}</span>
        </div>
      </div>
      
      <div className="border-t border-gray-200 pt-4">
        <div className="flex justify-between items-center">
          <span className="text-gray-600">Total</span>
          <span className="font-bold">£{total.toFixed(2)}</span>
        </div>
      </div>
      
      <button className="w-full bg-gradient-to-r from-golden-400 to-golden-600 text-white py-3 rounded-xl font-medium hover:opacity-90 transition-opacity mt-6">
        Checkout
      </button>
    </div>
  </div>
);

const Cart = ({ cartItems = {} ,properties}) => {

  useEffect(() => {
    document.title = 'Cart | Golden Groupe';
  }
  , []);
  const calculateTotals = () => {
    let subtotal = 0;
    Object.values(cartItems).forEach(item => {
      Object.values(item.items || {}).forEach(itemDetails => {
        subtotal += parseFloat(itemDetails.price) * itemDetails.quantity;
      });
    });
    return {
      subtotal,
      total: subtotal // Assuming no additional fees/discounts
    };
  };

  const { subtotal, total } = calculateTotals();

  return (
    <div className="container mx-auto p-6 flex flex-col md:flex-row gap-6">
      <div className="flex-grow">
        {Object.entries(cartItems).map(([propertyId, item]) => (
          <CartItem 
            key={propertyId}
            item={item}
            propertyDetails={properties.find(p => p.id == propertyId)}
          />
        ))}
      </div>
      
      <div className="md:w-96">
        <CartTotal subtotal={subtotal} total={total} />
      </div>
    </div>
  );
};

export default Cart;