import React from 'react';
import { FaFacebookF, FaInstagram, FaPhone } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-golden-navy text-white py-3 px-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <img src="/ico/gglogolarge.svg" alt="Golden Group" className="h-8" />
          <img src="/ico/trusted-stays.png" alt="Trustpilot" className="h-5" />
        </div>
        
        <div className="flex items-center space-x-6 text-sm">
          <a href="mailto:info@goldengroup.uk" className="hover:text-golden-yellow transition-colors">
            info@goldengroup.uk
          </a>
          <a 
            href="https://www.facebook.com/Goldengroup.uk" 
            target="_blank" 
            rel="noopener noreferrer"
            className="hover:text-golden-yellow transition-colors"
          >
            <FaFacebookF />
          </a>
          <a 
            href="https://www.instagram.com/goldengroup.uk/"
            target="_blank" 
            rel="noopener noreferrer"
            className="hover:text-golden-yellow transition-colors"
          >
            <FaInstagram />
          </a>
          <a 
            href="https://goldengroup.uk" 
            target="_blank"
            className="hover:text-golden-yellow transition-colors"
          >
            goldengroup.uk
          </a>
          <a 
            href="tel:02034422400" 
            className="flex items-center hover:text-golden-yellow transition-colors"
          >
            <FaPhone className="mr-1" />
            02034422400
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;