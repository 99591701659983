import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Properties from './components/Properties';
import Orders from './components/orders/Orders';
import Footer from './components/Footer';
import Login from './pages/Login';
import authService from './services/authService';
import propertiesService from './services/propertiesService';
import ordersService from './services/ordersService';
import config from './config';
import Cart from './components/Cart';
import Pricing from './components/Pricing';
import cartService from './services/cartService';
import Referer from './components/Referer';
import FloatingCart from './components/FloatingCart';

const calculateOrderStats = (orders = []) => {
  return {
    active: orders.filter(order => ['Scheduled', 'On-Route'].includes(order.order_status)).length,
    completed: orders.filter(order => order.order_status === 'Completed').length,
    failed: orders.filter(order => ['Failed', 'Cancelled'].includes(order.order_status)).length
  };
};

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [properties, setProperties] = useState([]);
  const [otherPropertiyData, setOtherPropertiyData] = useState(null);
  const [ordersData, setOrdersData] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const orderStats = calculateOrderStats(ordersData?.orders);
  const [orderSheduled, setOrderSheduled] = useState([]);


  const checkAuth = async () => {
    if (authService.isAuthenticated()) {
      try {
        const userData = await authService.getCurrentUser();
        setUser(userData);
        console.log(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        setIsAuthenticated(true);
        // Fetch orders and properties after authentication
        const ordersData = await ordersService.getAllOrders();
        const propertiesData = await propertiesService.getAllProperties();
        setOrders(ordersData.orders.data);
        setOrdersData(ordersData);
         setProperties(propertiesData.properties);
         setOtherPropertiyData(propertiesData);
         setOrderSheduled(propertiesData.order_schedule);

         //set only new propertiesData.cart to cartItems not existing in the cart

         if(cartItems.length === 0){
          setCartItems(propertiesData.cart);
          }else{
             let newCartItems = propertiesData.cart;
            let existingCartItems = cartItems;
            let existingCartItemsIds = existingCartItems.map((item) => item.id);
            newCartItems = newCartItems.filter((item) => !existingCartItemsIds.includes(item.id));
            setCartItems([...existingCartItems, ...newCartItems]); 
          }
          
           

        
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
    setLoading(false);
  };


  useEffect(() => {
    
    /*propertiesService.getSessionLog().then((response) => {
      console.log(response);
    });*/
    checkAuth();
  }, []);

  const logout = () => {
    authService.logout();
    setIsAuthenticated(false);
    setUser(null);
    setOrders([]);
    setProperties([]);
  };
  const removeFromCart = async (itemId) => {
    await cartService.removeFromCart(0, itemId);
 
    checkAuth()
  };

  const toggleSidebar = () => {
    setSidebarOpen(prevState => !prevState);
  };

  
  if (loading) {
    return <div>Loading...</div>;
  }
  const refreshProperties = async () => {
    checkAuth()
  }
  
  return (
    <Router>
      {isAuthenticated ? (
        <div className="flex flex-col min-h-screen">
          <div className="flex flex-1">
            <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} logout={logout} orderStats={orderStats} />
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
              <Header 
                toggleSidebar={toggleSidebar} 
                user={user} 
                logout={logout}
                appName={config.APP_NAME}
              />
               <FloatingCart cartItems={cartItems}  properties={properties} refreshProperties={refreshProperties} setCartItems={setCartItems}   position="bottom" />
              <Routes>
                <Route path="/" element={<Dashboard properties={properties} data={otherPropertiyData} orders={ordersData} refreshProperties={refreshProperties} />} />
                <Route path="/properties" element={<Properties properties={properties} orderSheduled={orderSheduled} data={otherPropertiyData} orders={ordersData} refreshProperties={refreshProperties} setCartItems={setCartItems} />} />
                <Route path="/pricing" element ={<Pricing />} />
                <Route path="/orders/*" element={<Orders ordersData={ordersData} propertyData={otherPropertiyData} />} />
                <Route path="/cart" element={<Cart cartItems={cartItems} orderSheduled={orderSheduled} propertyData={otherPropertiyData} ordersData={ordersData} properties={properties} removeFromCart={removeFromCart} />} />
                <Route path="/refer" element={<Referer />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </main>
          </div>
          <Footer 
            appName={config.APP_NAME}
            version={config.VERSION}
            supportEmail={config.SUPPORT_EMAIL}
          />
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login checkAuth={checkAuth} setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;