import React, { useState, useEffect } from 'react';
import { 
  LineChart, 
  Line, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';
import { FiBarChart2, FiTrendingUp } from 'react-icons/fi';

const DashboardChart = ({ orders = [] }) => {
  const [chartType, setChartType] = useState('line');
  const [timeFilter, setTimeFilter] = useState('week');
  const [chartData, setChartData] = useState([]);

  const getWeekData = () => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

    const weekData = days.map((day, index) => {
      const currentDay = new Date(startOfWeek);
      currentDay.setDate(startOfWeek.getDate() + index);
      
      const dayOrders = orders.filter(order => {
        const orderDate = new Date(order.property_date);
        return orderDate.toDateString() === currentDay.toDateString();
      });

      return {
        name: day,
        value: dayOrders.reduce((sum, order) => sum + parseFloat(order.order_total || 0), 0),
        orders: dayOrders.length
      };
    });

    return weekData;
  };

  const getMonthData = () => {
    const currentDate = new Date();
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

    return Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      const dayOrders = orders.filter(order => {
        const orderDate = new Date(order.property_date);
        return orderDate.getDate() === day && 
               orderDate.getMonth() === currentDate.getMonth() && 
               orderDate.getFullYear() === currentDate.getFullYear();
      });

      return {
        name: day.toString(),
        value: dayOrders.reduce((sum, order) => sum + parseFloat(order.order_total || 0), 0),
        orders: dayOrders.length
      };
    });
  };

  const getYearData = () => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentYear = new Date().getFullYear();

    return months.map((month, index) => {
      const monthOrders = orders.filter(order => {
        const orderDate = new Date(order.property_date);
        return orderDate.getMonth() === index && 
               orderDate.getFullYear() === currentYear;
      });

      return {
        name: month,
        value: monthOrders.reduce((sum, order) => sum + parseFloat(order.order_total || 0), 0),
        orders: monthOrders.length
      };
    });
  };

  useEffect(() => {
    let data;
    switch (timeFilter) {
      case 'week':
        data = getWeekData();
        break;
      case 'month':
        data = getMonthData();
        break;
      case 'year':
        data = getYearData();
        break;
      default:
        data = getWeekData();
    }
    setChartData(data);
  }, [timeFilter, orders]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-golden-navy">
          This {timeFilter === 'week' ? 'Week' : timeFilter === 'month' ? 'Month' : 'Year'}'s Order Value & Orders
        </h2>
        
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <button
              onClick={() => setChartType('line')}
              className={`flex items-center px-6 py-2 rounded-lg ${
                chartType === 'line' 
                  ? 'bg-golden-navy text-white' 
                  : 'bg-gray-100'
              }`}
            >
              <FiTrendingUp className="mr-2" /> Line
            </button>
            <button
              onClick={() => setChartType('bar')}
              className={`flex items-center px-6 py-2 rounded-lg ${
                chartType === 'bar' 
                  ? 'bg-golden-navy text-white' 
                  : 'bg-gray-100'
              }`}
            >
              <FiBarChart2 className="mr-2" /> Bar
            </button>
          </div>

          <div className="bg-golden-navy rounded-lg overflow-hidden">
            <button
              onClick={() => setTimeFilter('week')}
              className={`px-6 py-2 ${
                timeFilter === 'week' ? 'bg-white text-golden-navy' : 'text-white'
              }`}
            >
              Week
            </button>
            <button
              onClick={() => setTimeFilter('month')}
              className={`px-6 py-2 ${
                timeFilter === 'month' ? 'bg-white text-golden-navy' : 'text-white'
              }`}
            >
              Month
            </button>
            <button
              onClick={() => setTimeFilter('year')}
              className={`px-6 py-2 ${
                timeFilter === 'year' ? 'bg-white text-golden-navy' : 'text-white'
              }`}
            >
              Year
            </button>
          </div>
        </div>
      </div>

      <div className="w-full h-[360px]">
        <ResponsiveContainer width="100%" height="100%">
          {chartType === 'line' ? (
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" orientation="left" tickFormatter={(value) => `£${value}`} />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip 
                formatter={(value, name) => {
                  if (name === "Orders") return `${value} orders`;
                  return `£${value.toFixed(2)}`;
                }}
              />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="value" name="Order Value" stroke="#1e3a8a" />
              <Line yAxisId="right" type="monotone" dataKey="orders" name="Orders" stroke="#fbbf24" />
            </LineChart>
          ) : (
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" orientation="left" tickFormatter={(value) => `£${value}`} />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip 
                formatter={(value, name) => {
                  if (name === "Orders") return `${value} orders`;
                  return `£${value.toFixed(2)}`;
                }}
              />
              <Legend />
              <Bar yAxisId="left" dataKey="value" name="Order Value" fill="#1e3a8a" />
              <Bar yAxisId="right" dataKey="orders" name="Orders" fill="#fbbf24" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DashboardChart;