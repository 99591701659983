import React, { useState, useRef, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { FiSearch, FiMail, FiBell, FiClock, FiUser, FiLogOut } from 'react-icons/fi';
import { IoMdArrowDropdown } from 'react-icons/io';

const Header = ({ user, toggleSidebar, isSidebar = false, logout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <header className="border bg-white border-b h-14 flex items-center px-4 text-sm">
      {isSidebar ? (
        <div className="borderflex items-center p-2">
          <img src="/logo.png" alt="Golden Group" className="h-12 mr-2" />
        </div>
      ) : (
        <>
          <button onClick={toggleSidebar} className="mr-4 text-gray-600 hover:text-gray-800 lg:hidden">
            <FaBars size={20} />
          </button>
          <div className="flex-grow flex items-center justify-between">
         {/*    <div className="relative w-full max-w-sm rounded-lg">
              <input
                type="text"
                placeholder="Search Order"
                className="w-full pl-8 pr-4 py-1.5 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-golden-blue"
              />
              <FiSearch className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
            </div> */}
            <div style={{width:'100%'}} className="bg-golden-yellow text-white p-1 text-center rounded-lg mb-2">
        <p>
          <img
            src="/ico/headphones.svg"
            alt="My Properties Icon"
            className="inline-block mr-2 h-8"
          />
          Contact us for assistance via WhatsApp or email to{" "}
          <span className="text-golden-navy"> <b>info@goldengroup.uk</b></span> for
          prompt support.
        </p>
      </div>
            <div className="flex items-center ml-4 space-x-4">
{/*               <FiMail className="text-gray-600 cursor-pointer" size={18} />
 */}              <FiBell className="text-gray-600 cursor-pointer" size={18} />
{/*               <FiClock className="text-gray-600 cursor-pointer" size={18} />
 */}              <div className="relative" ref={dropdownRef}>
                <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
                  <div className="w-7 h-7 bg-golden-blue rounded-full mr-2"></div>
                  <span className="font-medium text-gray-700 mr-1 text-sm">
                    {`${user?.first_name} ${user?.last_name}`}
                    <br />
                    <span className="text-xs text-gray-500">{user?.email}</span>
                  </span>
                  <IoMdArrowDropdown className="text-gray-600" size={18} />
                </div>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                    <a href="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                      <FiUser className="mr-2" />
                      Profile
                    </a>
                    <button onClick={logout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                      <FiLogOut className="mr-2" />
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default Header;