import config from '../config';
import apiService from '../utils/apiInterceptor';
import Cookies from 'js-cookie';
import { notification } from 'antd';

const authService = {
  login: async (email, password) => {
    const response = await apiService.post('/loginapp', { email, password });
    if (response.token) {
      localStorage.setItem(config.AUTH_TOKEN_KEY, response.token);
    }
    return response;
  },

  logout: async () => {
    const response = await apiService.post('/logout');
    if (response.message) {
        localStorage.removeItem(config.AUTH_TOKEN_KEY);
        localStorage.removeItem('user');
     
         // Clear all cookies
         const cookies = Cookies.get();
         for (const cookie in cookies) {
           Cookies.remove(cookie);
         }
       
    }
    notification.success({
        message: 'Logged Out',
        description: 'You have been successfully logged out.',
      });
   return response;
    
  },

  getCurrentUser: async () => {
    const response = await apiService.get('/user');
    return response;
  },

  isAuthenticated: () => {
    return !!localStorage.getItem(config.AUTH_TOKEN_KEY);
  }
};

export default authService;