import axios from 'axios';
import { notification } from 'antd';
import config from '../config';

const unauthorizedCodes = [401, 403];

const createApiInstance = (baseURL = config.API_URL) => {
  const instance = axios.create({
    baseURL,
    timeout: 60000, // 60 seconds
    withCredentials: true, // This is important for handling cookies
  });

  // Request interceptor
  instance.interceptors.request.use(
    (reqConfig) => {
      const token = localStorage.getItem(config.AUTH_TOKEN_KEY);
      if (token) {
        reqConfig.headers['Authorization'] = `Bearer ${token}`;
      }
      return reqConfig;
    },
    (error) => {
      notification.error({ message: 'Request Error', description: error.message });
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      handleApiError(error);
      return Promise.reject(error);
    }
  );

  return instance;
};

const handleApiError = (error) => {
  const { response } = error;
  const notificationParams = {
    message: 'Error',
    description: 'An unexpected error occurred.',
  };

  if (response) {
    if (unauthorizedCodes.includes(response.status)) {
      notificationParams.message = 'Authentication Failed';
      notificationParams.description = response.data.message || 'Please login again';
      
     // localStorage.removeItem(config.AUTH_TOKEN_KEY);
      // You might want to redirect to login page here
      // window.location.href = '/login';
    } else if (response.status === 404) {
      notificationParams.message = 'Not Found';
      notificationParams.description = response.data.message;
    } else if (response.status === 500) {
      notificationParams.message = 'Internal Server Error';
    }
  }

  notification.error(notificationParams);
};

const apiService = createApiInstance();

export default apiService;