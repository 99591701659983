const config = {
    API_URL: process.env.REACT_APP_API_URL || 'https://portal.goldengrouplinen.uk/api',
    APP_NAME: process.env.REACT_APP_APP_NAME || 'Golden Group Linen',
    VERSION: process.env.REACT_APP_VERSION || '1.0.0',
    AUTH_TOKEN_KEY: process.env.REACT_APP_AUTH_TOKEN_KEY || 'auth_token',
    SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || 'info@goldengroup.uk',
    SUPPORT_PHONE: process.env.REACT_APP_SUPPORT_PHONE || '+44 20 1234 5678',
    WHATSAPP_NUMBER: process.env.REACT_APP_WHATSAPP_NUMBER || '+44 20 9876 5432',
    CURRENCY: process.env.REACT_APP_CURRENCY || 'GBP',
    DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT || 'DD-MM-YYYY',
    TIME_FORMAT: process.env.REACT_APP_TIME_FORMAT || 'HH:mm',
    MAX_UPLOAD_SIZE: 5 * 1024 * 1024, // 5MB
    ALLOWED_FILE_TYPES: ['image/jpeg', 'image/png', 'application/pdf'],
  };
  
  export default config;