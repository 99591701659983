import React from 'react';
import ReactTable from 'react-table-v6';
import { FiEye } from 'react-icons/fi';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';

const TableStyles = styled.div`
  .ReactTable {
    border: none;
    .rt-thead.-header {
      box-shadow: none;
      background: #f0f2f5;
      .rt-th {
        text-align: left;
        padding: 12px 15px;
        border-right: none;
        font-weight: bold;
        color: #1e3a8a;
      }
    }
    .rt-tbody .rt-tr-group {
      border-bottom: 1px solid #e5e7eb;
    }
    .rt-tbody .rt-td {
      border-right: none;
      padding: 8px 15px;
    }
    .rt-tbody .rt-tr:not(.-padRow) {
      &:hover {
        background: #172554 !important;
        color: #fbbf24 !important;
        * {
          color: #fbbf24 !important;
        }
        .status-tag {
          background: transparent !important;
          border: 1px solid #fbbf24;
        }
      }
    }
    .rt-tbody .rt-tr-group:nth-child(even) {
      background: #f8fafc;
    }
    .-pagination {
      box-shadow: none;
      border-top: 1px solid #e5e7eb;
      padding: 0;
    }
  }
`;

const StatusTag = styled.span.attrs({ className: 'status-tag' })`
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  ${props => {
    switch (props.status) {
      case 'Scheduled':
        return `
          background: #dbeafe;
          color: #1e40af;
        `;
      case 'On-Route':
        return `
          background: #e0e7ff;
          color: #3730a3;
        `;
      case 'Completed':
        return `
          background: #dcfce7;
          color: #166534;
        `;
      case 'Collected':
        return `
          background: #f3e8ff;
          color: #6b21a8;
        `;
      case 'Cancelled':
        return `
          background: #fee2e2;
          color: #991b1b;
        `;
      case 'Failed':
        return `
          background: #ffe4e6;
          color: #9f1239;
        `;
      default:
        return `
          background: #f3f4f6;
          color: #1f2937;
        `;
    }
  }}
`;

const OrderTable = ({ orders, onViewDetails }) => {
  const getOptimalPageSize = (dataLength) => {
    const pageSizeOptions = [1,2,3,5, 10, 15];
    for (let size of pageSizeOptions) {
      if (dataLength <= size) {
        return size;
      }
    }
    return 15;
  };

  const columns = [
    {
      Header: 'Order ID',
      accessor: 'id',
      Cell: ({ value }) => `GGO-${value.toString().padStart(7, '0')}`,
    },
    {
      Header: 'Time',
      accessor: d => d.property_details[0]?.arrival_time,
      id: 'arrival_time'
    },
    {
      Header: 'City',
      accessor: 'delivery_city',
    },
    {
      Header: 'Apartment',
      accessor: 'apartment_number',
    },
    {
      Header: 'Total',
      accessor: 'order_total',
      Cell: ({ value }) => `£${parseFloat(value).toFixed(2)}`,
    },
    {
      Header: 'Status',
      accessor: 'order_status',
      Cell: ({ value }) => (
        <StatusTag status={value}>{value}</StatusTag>
      ),
    },
    {
      Header: 'Actions',
      id: 'actions',
      width: 100,
      Cell: ({ original }) => (
        <div className="flex justify-center">
          <button
            onClick={() => onViewDetails(original)}
            className="p-2"
          >
            <FiEye className="w-5 h-5" />
          </button>
        </div>
      )
    }
  ];

  const optimalPageSize = getOptimalPageSize(orders.length);

  return (
    <TableStyles>
      <ReactTable
        data={orders}
        columns={columns}
        defaultPageSize={optimalPageSize}
        pageSizeOptions={[5, 10, 15, 20, 50]}
        className="-striped -highlight"
        showPagination={orders.length > optimalPageSize}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                background: rowInfo.index % 2 === 0 ? '#f8fafc' : '#ffffff',
              }
            }
          }
          return {};
        }}
        getPaginationProps={() => ({
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }
        })}
      />
    </TableStyles>
  );
};

export default OrderTable;