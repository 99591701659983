import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';

const DashboardCardOrdersValue = ({ orders }) => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  const calculateOrderValue = (orders, timeFrame) => {
    return orders.reduce((total, order) => {
      const orderDate = new Date(order.property_date);
      
      switch(timeFrame) {
        case 'thisMonth':
          if (orderDate.getMonth() === currentMonth && 
              orderDate.getFullYear() === currentYear) {
            return total + parseFloat(order.order_total);
          }
          break;
        case 'lastMonth':
          if (orderDate.getMonth() === lastMonth && 
              orderDate.getFullYear() === lastMonthYear) {
            return total + parseFloat(order.order_total);
          }
          break;
        case 'year':
          if (orderDate.getFullYear() === currentYear) {
            return total + parseFloat(order.order_total);
          }
          break;
      }
      return total;
    }, 0);
  };

  const thisMonthValue = calculateOrderValue(orders, 'thisMonth');
  const lastMonthValue = calculateOrderValue(orders, 'lastMonth');
  const yearValue = calculateOrderValue(orders, 'year');
  const maxValue = Math.max(thisMonthValue, lastMonthValue, yearValue);

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center mb-4">
        <FaShoppingCart className="text-golden-navy mr-2 text-xl" />
        <h3 className="text-lg font-semibold text-golden-navy">Order Value</h3>
      </div>
      
      <div className="space-y-4">
        {/* This Month Value */}
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium">This Month</span>
            <span className="text-sm font-semibold text-emerald-600">£{thisMonthValue.toFixed(2)}</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-emerald-500 h-2 rounded-full transition-all duration-500"
              style={{ width: `${(thisMonthValue/maxValue) * 100}%` }}
            />
          </div>
        </div>

        {/* Last Month Value */}
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium">Last Month</span>
            <span className="text-sm font-semibold text-blue-600">£{lastMonthValue.toFixed(2)}</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-500 h-2 rounded-full transition-all duration-500"
              style={{ width: `${(lastMonthValue/maxValue) * 100}%` }}
            />
          </div>
        </div>

        {/* Yearly Value */}
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium">This Year</span>
            <span className="text-sm font-semibold text-purple-600">£{yearValue.toFixed(2)}</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-purple-500 h-2 rounded-full transition-all duration-500"
              style={{ width: `${(yearValue/maxValue) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCardOrdersValue;