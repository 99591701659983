import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import propertiesService from '../../services/propertiesService';
import { type } from '@testing-library/user-event/dist/type';

const NewPropertyForm = ({ onSave, onSaveAndAddToCart }) => {
    const [formData, setFormData] = useState({
        type: 1,
        property_type: '',
        property_title: '',
        contact_name: '',
        contact_number: '',
        appartment_address: '',
        appartment_number: '',
        post_code: '',
        delivery_city: '',
        delivery_country: '',
        arrival_times: ['', ''],
        completion_times: ['', ''],
        date: '',
        quantity: {}, // For rooms
        product_quantity: {}, // For products/packs
        cleaner_information: '',
        property_description: '',
        apartment_name: '',
      });
    
      const [propertyTypes, setPropertyTypes] = useState([]);
      const [countries, setCountries] = useState([]);
      const [categories, setCategories] = useState([]);
      const [products, setProducts] = useState({});
      const [packs, setPacks] = useState([]);
      const [rooms, setRooms] = useState([]);
    
      useEffect(() => {
        fetchAddPropertyData();
      }, []);
    
      const fetchAddPropertyData = async () => {
        let response = await propertiesService.addProperty();
        if (response) {
          setPropertyTypes(response.propertytype);
          setCountries(response.countries);
      
          // Initialize rooms with 0 count
          const initialRooms = {};
          response.rooms.forEach(room => {
            initialRooms[room.id] = 0;
          });
          setCategories(response.categories);
          setProducts(response.products || {});
          setPacks(response.packs || []);
            setRooms(response.rooms || []);
    
          setFormData(prevData => ({
            ...prevData,
            quantity: initialRooms
          }));
        }
      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'arrival_time' || name === 'completion_time') {
          const [hours, minutes] = value.split(':');
          setFormData(prevData => ({
            ...prevData,
            [`${name}s`]: [hours, minutes]
          }));
        } else {
          setFormData({ ...formData, [name]: value });
        }
      };
    
      const handleQuantityChange = (itemId, change, isProduct, price) => {
        setFormData(prevData => {
          const currentQuantity = prevData.product_quantity[itemId]?.qty || 0;
          const newQuantity = Math.max(0, currentQuantity + change);
          
          if (newQuantity === 0) {
            const { [itemId]: _, ...rest } = prevData.product_quantity;
            return { ...prevData, product_quantity: rest };
          }
    
          return {
            ...prevData,
            product_quantity: {
              ...prevData.product_quantity,
              [itemId]: { 
                id: itemId,
                price: price,
                type: isProduct ? 'product' : 'pack',
                qty: newQuantity
              }
            }
          };
        });
      };
    
      const handleRentTypeChange = (productId, rentType) => {
        setFormData(prevData => ({
          ...prevData,
          product_quantity: {
            ...prevData.product_quantity,
            [productId]: { ...prevData.product_quantity[productId], rentType }
          }
        }));
      };
    
      const handleRoomChange = (roomId, value) => {
        setFormData(prevData => ({
          ...prevData,
          quantity: { ...prevData.quantity, [roomId]: parseInt(value) }
        }));
      };
    
 
      const prepareDataToSend = () => {
        const dataToSend = new FormData();
    
        // Add _token (you might need to get this from somewhere)
     
        // Add basic fields
        Object.entries(formData).forEach(([key, value]) => {
          if (!['quantity', 'product_quantity', 'arrival_times', 'completion_times'].includes(key)) {
            dataToSend.append(key, value);
          }
        });
    
        // Add arrival and completion times
        formData.arrival_times.forEach(time => dataToSend.append('arrival_times[]', time));
        formData.completion_times.forEach(time => dataToSend.append('completion_times[]', time));
    
        // Add quantity
        Object.entries(formData.quantity).forEach(([roomId, count]) => {
          dataToSend.append(`quantity[${roomId}]`, count);
        });
    
        // Add product_quantity
        Object.entries(formData.product_quantity).forEach(([productId, product]) => {
          Object.entries(product).forEach(([field, value]) => {
            dataToSend.append(`product_quantity[${productId}][${field}]`, value);
          });
        });
    
        return dataToSend;
      };
    
      const handleSave = () => {
        const dataToSend = prepareDataToSend();
        dataToSend.set('type', '1'); // Assuming type 2 is for save and add to cart

        onSave(dataToSend);
      };
    
      const handleSaveAndAddToCart = () => {
        const dataToSend = prepareDataToSend();
        dataToSend.set('type', '2'); // Assuming type 2 is for save and add to cart
        onSaveAndAddToCart(dataToSend);
      };

  return (
    <div className="border rounded-lg overflow-y-auto px-6 pt-6 pb-6">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

      {/* About Section */}
      <div className="bg-white p-6 rounded-lg shadow border">
        <h3 className="text-xl font-semibold mb-4">About</h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Property ID*</label>
              <input
                type="text"
                name="property_title"
                value={formData.property_title}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Property Type*</label>
              <select
                name="property_type"
                value={formData.property_type}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              >
                <option value="">Select Type</option>
                {propertyTypes.map(type => (
                  <option key={type.id} value={type.id}>{type.title}</option>
                ))}
              </select>
            </div>
            <div>
            <label className="block text-sm font-medium text-gray-700">Property Description</label>
            <textarea
              name="property_description"
              value={formData.property_description}
              onChange={handleInputChange}
              rows="3"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
            ></textarea>
          </div>
          </div>
        </div>
      </div>
      {/* Contact Section */}
      <div className="bg-white p-6 rounded-lg shadow border">
        <h3 className="text-xl font-semibold mb-4">Contact</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Name*</label>
            <input
              type="text"
              name="contact_name"
              value={formData.contact_name}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Access</label>
              <input
                type="text"
                name="cleaner_information"
                value={formData.cleaner_information}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Contact Number*</label>
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Description</label>
            <textarea
              name="contact_description"
              value={formData.contact_description}
              onChange={handleInputChange}
              rows="3"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
            ></textarea>
          </div>
        </div>
      </div>

      {/* Rooms Section */}
      <div className="bg-white p-6 rounded-lg shadow border">
        <h3 className="text-xl font-semibold mb-4">Rooms</h3>
        <div className="space-y-4">
          {Object.entries(formData.quantity).map(([roomId, count]) => (
            <div key={roomId} className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-700 capitalize">{rooms.find((r) => r.id === parseInt(roomId))?.room_title}</span>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => handleRoomChange(roomId, Math.max(0, count - 1))}
                  className="bg-gray-200 rounded-full w-6 h-6 flex items-center justify-center focus:outline-none"
                >
                  -
                </button>
                <span className="mx-2 w-8 text-center">{count}</span>
                <button
                  type="button"
                  onClick={() => handleRoomChange(roomId, count + 1)}
                  className="bg-gray-200 rounded-full w-6 h-6 flex items-center justify-center focus:outline-none"
                >
                  +
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>


      {/* Default Timeslot Section */}
      <div className="bg-white p-6 rounded-lg shadow border">
        <h3 className="text-xl font-semibold mb-4">Default Timeslot</h3>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Arrival Time*</label>
              <input
                type="time"
                name="arrival_time"
                value={`${formData.arrival_times[0].padStart(2, '0')}:${formData.arrival_times[1].padStart(2, '0')}`}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Completion Time*</label>
              <input
                type="time"
                name="completion_time"
                value={`${formData.completion_times[0].padStart(2, '0')}:${formData.completion_times[1].padStart(2, '0')}`}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
            />
          </div>
        </div>
      </div>

        <div className="bg-white p-6 rounded-lg shadow col-span-2 border">
          <h3 className="text-xl font-semibold mb-4">Address</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Address*</label>
              <input
                type="text"
                name="appartment_address"
                value={formData.appartment_address}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Apartment/Building No*</label>
              <input
                type="text"
                name="appartment_number"
                value={formData.appartment_number}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
              />
            </div>
            
          </div>
          <div className="grid grid-cols-3 gap-4 mt-4">

<div>
  <label className="block text-sm font-medium text-gray-700">
    City*
  </label>
  <input
    type="text"
    name="delivery_city"
    value={formData.delivery_city}
    onChange={handleInputChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
  />
</div>
<div>
  <label className="block text-sm font-medium text-gray-700">
    Country*
  </label>
  <select
    name="delivery_country"
    value={formData.delivery_country}
    onChange={handleInputChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
  >
    <option value="">Select Country</option>
    {countries.map((country) => (
      <option key={country.id} value={country.sortname}>
        {country.name}
      </option>
    ))}
  </select>
</div>
<div>
  <label className="block text-sm font-medium text-gray-700">
    Post Code*
  </label>
  <input
    type="text"
    name="post_code"
    value={formData.post_code}
    onChange={handleInputChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-golden-blue focus:border-golden-blue"
  />
</div>
</div>
        </div>
      </div>

      <DefaultOrder 
        categories={categories} 
        products={products} 
        packs={packs}
        defaultOrder={formData.product_quantity}
        onQuantityChange={handleQuantityChange}
        onRentTypeChange={handleRentTypeChange}
      />

      <div className="mt-6 flex justify-end space-x-4">
        <button
          type="button"
          onClick={handleSave}
          className="bg-golden-navy text-white px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-300"
        >
            Save Property Info
        </button>
        <button
          type="button"
          onClick={handleSaveAndAddToCart}
          className="bg-golden-blue text-white px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-300"
        >
          Save Property Info & Add to Cart
        </button>
      </div>
    </div>
  );
};

const DefaultOrder = ({ categories, products, packs, defaultOrder, onQuantityChange, onRentTypeChange }) => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const categoryOrder = {
    'Linen Hire': 1,
    'Essentials': 2,
    'Cleaning Service': 3,
    'Consumable': 4,
    'Baby Equipment Hire': 5
  };

  const sortedCategories = [...categories].sort((a, b) => {
    const orderA = categoryOrder[a.category_title] || 999;
    const orderB = categoryOrder[b.category_title] || 999;
    return orderA - orderB;
  });

  return (
    <div className="bg-blue-100 p-6 rounded-lg border shadow mt-6">
      <h3 className="text-2xl font-semibold mb-4 flex items-center">
        <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
        Default Order
      </h3>
      <p className="text-sm text-gray-600 mb-4">Disclaimer: All prices are including VAT under the total amount</p>
      <div className="space-y-2">
        {sortedCategories.map((category) => (
          <CategorySection
            key={category.id}
            category={category}
            products={products[category.id] || []}
            packs={category.id === 1 ? packs : []}
            defaultOrder={defaultOrder}
            onQuantityChange={onQuantityChange}
            onRentTypeChange={onRentTypeChange}
            isExpanded={expandedCategory === category.id}
            onToggle={() => setExpandedCategory(expandedCategory === category.id ? null : category.id)}
          />
        ))}
      </div>
    </div>
  );
};

const CategorySection = ({ category, products, packs, defaultOrder, onQuantityChange, onRentTypeChange, isExpanded, onToggle }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden">
      <button
        className="w-full p-4 text-left font-medium flex justify-between items-center"
        onClick={onToggle}
      >
        {category.category_title}
        {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
      </button>
      {isExpanded && (
        <div className="p-4 border-t">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {packs.map(pack => (
              <PackItem 
                key={pack.id} 
                pack={pack} 
                defaultOrder={defaultOrder}
                onQuantityChange={onQuantityChange}
              />
            ))}
            {products.map(product => (
              <ProductItem 
                key={product.id} 
                product={product} 
                categoryTypeId={category.category_type_id}
                defaultOrder={defaultOrder}
                onQuantityChange={onQuantityChange}
                onRentTypeChange={onRentTypeChange}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const PackItem = ({ pack, defaultOrder, onQuantityChange }) => {
  const quantity = defaultOrder[pack.id]?.qty || 0;

  return (
    <div className="bg-white p-4 shadow border">
      <div className="flex items-center mb-2">
        <img src={pack.image || "/ico/gglogolarge.svg"} alt={pack.name} className="w-16 h-16 object-cover rounded mr-4" />
        <div>
          <h4 className="font-medium">{pack.name}</h4>
          <p className="text-sm text-gray-600">{pack.description}</p>
          <div className="flex justify-between items-center mt-2">
            <span className="text-navy-600 font-bold">{`£${pack.price.toFixed(2)}`}</span>
            <div className="flex items-center">
              <button onClick={() => onQuantityChange(pack.id, -1, false,pack.price)} className="text-navy-900 border">
                <FiChevronLeft />
              </button>
              <span className="mx-2 w-10 text-center border">{quantity.toString().padStart(2, '0')}</span>
              <button onClick={() => onQuantityChange(pack.id, 1, false,pack.price)} className="text-navy-900 border">
                <FiChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductItem = ({ product, categoryTypeId, defaultOrder, onQuantityChange, onRentTypeChange }) => {
  const quantity = defaultOrder[product.id]?.qty || 0;
  const selectedRentType = defaultOrder[product.id]?.rentType || '3days';

  const rentTypes = [
    { key: '3days', label: '3 Days', priceKey: 'priceone' },
    { key: '1week', label: '1 Week', priceKey: 'pricetwo' },
    { key: '2weeks', label: '2 Weeks', priceKey: 'pricethree' },
    { key: '1month', label: '1 Month', priceKey: 'pricefour' },
  ];

  return (
    <div className="bg-white p-4 shadow border">
      <div className="flex items-center mb-2">
        <img src={product.image || "/ico/gglogolarge.svg"} alt={product.product_title} className="w-16 h-16 object-cover rounded mr-4" />
        <div style={{width:'100%'}}>
          <h4 className="font-medium">{product.product_title}</h4>
          <p style={{minHeight:30,width:'100%'}} className="text-sm text-gray-600">{product.product_description || product.product_title}</p>
          {categoryTypeId === 1 ? (
            <div className="flex justify-between items-center mt-2">
              <span className="text-navy-900 font-bold">{`£${product.product_price.toFixed(2)}`}</span>
              <div className="flex items-center">
                <button onClick={() => onQuantityChange(product.id, -1,true,product.product_price)} className="text-navy-500 border">
                  <FiChevronLeft />
                </button>
                <span className="mx-2 w-10 text-center border text-navy-900">{quantity.toString().padStart(2, '0')}</span>
                <button onClick={() => onQuantityChange(product.id, 1,true,product.product_price)} className="text-navy-500 border rounded">
                  <FiChevronRight />
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <select
                value={selectedRentType}
                onChange={(e) => onRentTypeChange(product.id, e.target.value)}
                className="w-full p-2 border rounded mb-2"
              >
                {rentTypes.map(({ key, label, priceKey }) => (
                  <option key={key} value={key}>
                    {`${label}: £${product[priceKey]?.toFixed(2)}`}
                  </option>
                ))}
              </select>
              <div className="flex justify-between items-center">
                <span className="text-navy-600 font-bold">{`£${product[rentTypes.find(rt => rt.key === selectedRentType).priceKey].toFixed(2)}`}</span>
                <div className="flex items-center">
                  <button onClick={() => onQuantityChange(product.id, -1,true,product[rentTypes.find(rt => rt.key === selectedRentType).priceKey])} className="text-gray-500">
                    <FiChevronLeft />
                  </button>
                  <span className="mx-2 w-8 text-center">{quantity.toString().padStart(2, '0')}</span>
                  <button onClick={() => onQuantityChange(product.id, 1,true, product[rentTypes.find(rt => rt.key === selectedRentType).priceKey])} className="text-gray-500">
                    <FiChevronRight />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPropertyForm;