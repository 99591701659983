import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const DashboardCardOrders = ({ title, orderStats }) => {
  const { active, completed, collected, failed } = orderStats;
  const total = completed + collected + failed;

  const data = [
    { name: 'Active', value: active },
    { name: 'Completed', value: completed + collected},
    { name: 'Failed/Cancelled', value: failed }
  ];

  const COLORS = ['#60A5FA', '#34D399', '#F87171'];

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold text-golden-navy mb-4">{title}</h3>
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-green-400 mr-2"></div>
              <p className="text-sm">
                Active Orders: <span className="font-semibold">{active}</span>
              </p>
            </div>
            
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-blue-400 mr-2"></div>
              <p className="text-sm">
                Completed Orders: <span className="font-semibold">{completed}</span>
              </p>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-red-400 mr-2"></div>
              <p className="text-sm">
                Failed/Cancelled: <span className="font-semibold">{failed}</span>
              </p>
            </div>
            <p className="text-sm font-medium mt-2">
              Total Orders: <span className="font-bold">{total}</span>
            </p>
          </div>
        </div>
        <div className="w-24 h-24">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={25}
                outerRadius={35}
                paddingAngle={5}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DashboardCardOrders;