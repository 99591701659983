import React, { useState, useEffect } from 'react';
import { FiUsers, FiCopy, FiShare2, FiUserPlus, FiGift } from 'react-icons/fi';
import { notification } from 'antd';

const Referer = () => {
  const [referralCode, setReferralCode] = useState('');

  useEffect(() => {
    // Get user data from local storage
    const userData = JSON.parse(localStorage.getItem('user'));
    
    if (userData) {
      // Create a unique referral code based on user's ID and first 3 chars of email
      const emailPrefix = userData.email.substring(0, 3).toUpperCase();
      const userId = userData.id.toString().padStart(4, '0');
      const uniqueCode = `GG${emailPrefix}${userId}`;
      setReferralCode(uniqueCode);
    }
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Copied!',
      description: 'Referral code copied to clipboard',
      placement: 'bottomRight',
    });
  };

  return (
    <div className="p-6 bg-golden-light min-h-screen">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-golden-navy flex items-center mb-4">
          <FiUsers className="mr-2" />
          Invite a friend
        </h1>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
        {/* Referral Message */}
        <div className="text-center mb-8">
          <p className="text-lg leading-relaxed text-golden-navy">
        When you refer someone, once their order reaches £1000, you'll receive a discount on your next invoice as a thank-you for joining our community.
          </p>
        </div>

        {/* User's Referral Code */}
        <div className="flex items-center justify-center space-x-4 mb-8">
          <div className="bg-golden-light px-4 py-2 rounded-lg text-golden-navy font-mono font-bold">
            {referralCode || 'Loading...'}
          </div>
          <button 
            onClick={() => copyToClipboard(referralCode)}
            className="bg-golden-navy text-white px-4 py-2 rounded-lg flex items-center hover:bg-opacity-90 transition-colors"
            disabled={!referralCode}
          >
            <FiCopy className="mr-2" />
            Copy Code
          </button>
        </div>

        {/* How it Works */}
        <div className="border-t pt-6">
          <h3 className="text-center text-golden-navy font-semibold mb-6">How it Works</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="text-center">
              <div className="bg-golden-light w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-3">
                <FiShare2 className="text-xl text-golden-navy" />
              </div>
              <h4 className="font-semibold mb-2">Share Code</h4>
              <p className="text-sm text-gray-600">Share your unique referral code with friends</p>
            </div>
            <div className="text-center">
              <div className="bg-golden-light w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-3">
                <FiUserPlus className="text-xl text-golden-navy" />
              </div>
              <h4 className="font-semibold mb-2">Friend Registers</h4>
              <p className="text-sm text-gray-600">Friend registers using your referral code</p>
            </div>
            <div className="text-center">
              <div className="bg-golden-light w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-3">
                <FiGift className="text-xl text-golden-navy" />
              </div>
              <h4 className="font-semibold mb-2">Get Rewards</h4>
              <p className="text-sm text-gray-600">Both receive discount when friend's order reaches £1000</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referer;