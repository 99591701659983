import apiService from '../utils/apiInterceptor';

const cartService = {
 
  removeFromCart: async (id,pid) => {
    return await apiService.get(`/remove-cart/${id}/${pid}`);
  },
  addToCart: async (pid) => {
    return await apiService.post(`/default-cart`,
        {property_id:pid}
    );
  }
};

export default cartService;



 