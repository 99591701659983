import apiService from '../utils/apiInterceptor';

const propertiesService = {
  getAllProperties: async () => {
    return await apiService.get('/properties');
  },

  getSessionLog: async () => {
    return await apiService.get('/session-log');
  } ,

  getPropertyById: async (id) => {
    return await apiService.get(`/edit-property/${id}`);
  },

  createProperty: async (propertyData) => {
    return await apiService.post('/submit-property', propertyData);
  },
  addProperty: async () => {
    return await apiService.get('/add-property');
  },

  updateProperty: async (propertyData) => {
    return await apiService.post(`/submit-property`, propertyData);
  },
  updatePropertyStatus: async (id, status) => {
    return await apiService.put(`/update-property-status/${id}`, {status: status});
  },

  deleteProperty: async (id) => {
    return await apiService.delete(`/properties/${id}`);
  }
};

export default propertiesService;