// Sidebar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  FiHome, FiSearch, FiTag, FiCheck, FiX,
  FiGrid, FiUpload, FiDollarSign, FiLogOut, 
  FiSettings, FiHeadphones, FiUsers, FiShoppingCart,
  FiClock
} from 'react-icons/fi';
import Header from './Header';

const MenuItem = ({ icon: Icon, to, children, border, iconColor = 'text-golden-blue', count }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li style={{marginTop: border ? '-12px !important' : ''}}>
      <Link 
        to={to} 
        className={`flex items-center justify-between ${!border ? 'p-2' : 'pl-2'} text-sm ${isActive ? 'text-golden-yellow' : 'text-white'} hover:bg-golden-blue hover:bg-opacity-20 rounded transition duration-150 ease-in-out`}
      >
        <div className="flex items-center">
          <Icon className={`h-4 w-4 mr-2 ${isActive ? 'text-golden-yellow' : iconColor}`} />
          {border ? <p style={{height:30}} className='border-l text-golden-navy pr-2'> | </p> : null}
          <span>{children}</span>
        </div>
        {count !== undefined && (
          <span className="bg-golden-light text-golden-navy px-2 py-0.5 rounded-full text-xs">
            {count}
          </span>
        )}
      </Link>
    </li>
  );
};

const Sidebar = ({ isOpen, toggleSidebar, orderStats , logout}) => {
  const location = useLocation();

  const orderMenuItems = [
    { 
      label: 'Active Orders', 
      icon: FiClock, 
      path: '/orders/active',
      count: orderStats?.active || 0
    },
    { 
      label: 'Completed Orders', 
      icon: FiCheck, 
      path: '/orders/completed',
      count: orderStats?.completed || 0
    },
    { 
      label: 'Failed/Cancelled', 
      icon: FiX, 
      path: '/orders/failed',
      count: orderStats?.failed || 0
    }
  ];

  return (
    <>
      <aside style={{height:'95vh'}} className={`bg-golden-navy text-white w-56 flex flex-col transition-all duration-300 ease-in-out fixed lg:relative ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 z-20`}>
        <Header isSidebar={true} toggleSidebar={toggleSidebar} />
        <div className="flex-grow overflow-y-auto p-3 text-sm">
          <h2 className="text-base font-bold mb-3">Main Menu</h2>
          <nav>
            <ul className="space-y-1">
              <MenuItem to="/" icon={FiHome}>Dashboard</MenuItem>
              <MenuItem to="/properties" icon={FiHome}>Properties</MenuItem>
{/*               <MenuItem to="/lead-search" icon={FiSearch}>Lead Search</MenuItem>
 */}              <MenuItem to="/orders" icon={FiClock}>Orders</MenuItem>

             </ul>
          </nav>
          
         

          <nav>
            <ul className="space-y-1">
{/*               <MenuItem to="/bulk-orders-upload" icon={FiUpload}>Bulk Orders Upload</MenuItem>
 */}              {/* <MenuItem to="/bulk-property-upload" icon={FiUpload}>Bulk Property Upload</MenuItem> */}
              <MenuItem to="/pricing" icon={FiDollarSign}>Pricing</MenuItem>
            </ul>
          </nav>
          
          <h2 className="text-base font-bold mt-6 mb-3">Other Menu</h2>
          <nav>
            <ul className="space-y-1">
{/*               <MenuItem to="/settings" icon={FiSettings}>Settings</MenuItem>
 */}              <MenuItem to="/support"  icon={FiHeadphones}>Support (Coming Soon)</MenuItem>
              <MenuItem to="/refer" icon={FiUsers}>Refer a Friend</MenuItem>
 
            </ul>
          </nav>

          <h2 className="text-base font-bold mt-6 mb-3"></h2>
          <nav>
            <ul className="space-y-3">
            <span onClick={logout}><MenuItem  to="#logout" icon={FiLogOut}>Logout</MenuItem></span>

            </ul>
          </nav>
        </div>
      </aside>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-10 lg:hidden" 
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Sidebar;