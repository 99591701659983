import React, { useState, useRef } from 'react';
import { FiShoppingCart, FiHome } from 'react-icons/fi';
import { Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import cartService from '../services/cartService';

const FloatingCart = ({ cartItems = {},properties,setCartItems,refreshProperties}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const hoverTimeoutRef = useRef(null);
  const [cart, setCart] = useState(cartItems);
  
  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovered(true);
  };
  const removeFromCart = async (id, property_id) => {
    try {
      let response = await cartService.removeFromCart(id, property_id);
      if (response) {
        notification.success({
          message: "Successful",
          description: `${response.message}!`,
        });
        
        if (cart) {
          // Since cart is an object, we need to use object methods to filter
          const updatedCart = { ...cart };
          delete updatedCart[property_id]; // Remove the property using its ID
          
          setCart(updatedCart);
          setCartItems(updatedCart); // Update cartItems as well if needed
        }
        
        refreshProperties();
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 300);
  };

  return (
    <div 
      className="fixed bottom-20 right-4 z-50"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative">
        <button
          onClick={() => navigate('/cart')}
          style={{border:'2px solid white',borderRadius:50}}
          className="bg-golden-navy text-white p-3 rounded shadow-lg hover:bg-opacity-90 transition-all duration-300 flex items-center"
        >
          <div className="relative">
            <FiShoppingCart className="h-6 w-6" />
            {Object.keys(cartItems).length > 0 && (
              <span className="absolute -top-6 -right-4 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                {Object.keys(cartItems).length}
              </span>
            )}
          </div>
        </button>

        {isHovered && Object.keys(cartItems).length > 0 && (
          <div className="absolute bottom-full right-0 mb-0 w-96 bg-white rounded-lg shadow-xl border ">
            <div className="divide-y max-h-96 overflow-y-auto min-h-[300px] max-h-[300px]">
              {Object.entries(cartItems).map(([propertyId, pp]) => {
                let property = properties.find(i=>i.id == propertyId);
              return (
                <div key={propertyId} className="p-0 flex items-center justify-between hover:bg-gray-50">
                  <div className="flex items-center space-x-0">
                  <img 
                src={property.property_type_id === 1 ? '/ico/propertyHouseIco.svg' : '/ico/propertyBuildingIco.svg'} 
                alt="Property Icon" 
                className="h-6 w-6 mx-1"
              />                    <div className='w-full' >
                      <div className="font-medium">{property?.property_title}</div>
                      <div style={{minHeight:'24px',fontSize:'10px'}} className="text-sm text-gray-500">{property?.apartment_address} {property?.apartment_number},{property?.delivery_zip_code} {property?.delivery_city} </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <span className="text-sm text-gray-500">
                      {new Date().toLocaleDateString('en-GB', { 
                        weekday: 'short',
                        day: 'numeric'
                      })}
                    </span>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromCart(0,propertyId);
                      }}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              )})}
            </div>
            
            <div className="p-1 bg-gray-50 rounded-b-lg">
              <button 
                onClick={() => navigate('/cart')}
                className="w-full text-white py-1 rounded bg-golden-navy transition-all duration-300"
              >
                GO TO CART
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatingCart;