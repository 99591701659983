import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import authService from '../services/authService';
import apiService from '../utils/apiInterceptor';
import config from '../config';

const Login = ({ setIsAuthenticated, checkAuth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await authService.login(email, password);
      if (response.token) {
        
        setIsAuthenticated(true);
        notification.success({
          message: 'Login Successful',
          description: `Welcome back to ${config.APP_NAME}!`,
        });
        checkAuth();
        navigate('/');
      } else {
        throw new Error('Login failed');
      }
    } catch (err) {
      notification.error({
        message: 'Login Failed',
        description: err.response?.data?.message || 'Invalid email or password',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-golden-navy">
      <div className="w-full max-w-md p-8 space-y-8 bg-golden-navy text-white">
        <div className="text-center">
          <img src="/ico/gglogolarge.svg" alt="Golden Group Logo" className="mx-auto w-64" />
          <h2 className="mt-6 text-3xl font-bold">Welcome Back</h2>
          <p className="mt-2">Log in to your account so you can continue using our customer experience.</p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium">
                Email Address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-golden-yellow rounded-md text-golden-navy placeholder-gray-400 focus:outline-none focus:ring-golden-yellow focus:border-golden-yellow"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-golden-yellow rounded-md text-golden-navy placeholder-gray-400 focus:outline-none focus:ring-golden-yellow focus:border-golden-yellow"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-golden-yellow focus:ring-golden-yellow border-golden-yellow rounded"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm">
                Remember Me
              </label>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-golden-navy bg-white hover:bg-golden-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-golden-yellow"
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>

          <div>
            <button
              type="button"
              className="w-full flex justify-center py-2 px-4 border border-golden-yellow rounded-md shadow-sm text-sm font-medium text-white bg-golden-yellow hover:bg-golden-yellow-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-golden-yellow"
              onClick={() => navigate('/')}
            >
              Back To Home
            </button>
          </div>
        </form>

        <div className="flex items-center justify-between mt-4 text-sm">
          <a href="/register" className="font-medium text-golden-yellow hover:text-golden-yellow-light">
            Create new account
          </a>
          <a href="/forgot-password" className="font-medium text-golden-yellow hover:text-golden-yellow-light">
            Forgot Password
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;