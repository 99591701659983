import React, { useState, useEffect, useRef } from 'react';
import { FiShoppingCart, FiTrash2 } from 'react-icons/fi';
import { FaHome, FaTag, FaShoppingCart, FaRecycle } from 'react-icons/fa';
import { FaDeleteLeft, FaDollarSign, FaMinus } from 'react-icons/fa6';
import productService from '../services/productService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Pricing = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const tableRef = useRef(null);

    const fetchPricing = async() => {
        try {
            let response = await productService.pricing();
            if (response?.products) {
                setProducts(response.products);
                const categoryIds = [...new Set(response.products.map(p => p.category_id))];
                setCategories(categoryIds.sort((a, b) => a - b));
            }
        } catch (error) {
            console.error('Error fetching pricing:', error);
        }
    };

    useEffect(() => {
        fetchPricing();
    }, []);

    const getCategoryName = (categoryId) => {
        switch(categoryId) {
            case 1: return "LINEN HIRE";
            case 2: return "ESSENTIALS";
            case 3: return "CLEANING SERVICE";
            case 4: return "CONSUMABLE";
            case 5: return "BABY EQUIPMENT HIRE";
            default: return "Other";
        }
    };

    const exportPDF = async () => {
        if (!tableRef.current) return;
        
        try {
            const canvas = await html2canvas(tableRef.current);
            const imgData = canvas.toDataURL('image/png');
            
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });

            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save('pricing-table.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <div style={{maxHeight:'87vh', overflow:'scroll' }} className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-golden-navy flex items-center">
                    <FaDollarSign className="mr-2" />
                    Pricing
                </h1>
                
            </div>

            <div className="flex  mb-1">
            <div style={{height:'50px'}} className="text-red-500 mb-4 bg-golden-navy text-center text-white p-4 rounded-lg w-full">
                All our prices are including VAT, for further enquiry please contact us on info@goldengroupuk.uk
            </div>

            <button 
            style={{height:'50px'}}
                    className="items-center ml-2 w-60 bg-golden-navy text-white rounded-lg hover:bg-opacity-90 transition-colors duration-300"
                    onClick={exportPDF}
                >
                    Export as pdf
                </button>
            </div>

            <div className="bg-white rounded-lg shadow overflow-hidden" ref={tableRef}>
                <table className="w-full">
                    <thead className="bg-golden-navy text-white">
                        <tr>
                            <th className="p-4 text-left">Product ID</th>
                            <th className="p-4 text-left">Product</th>
                            <th className="p-4 text-right">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map(categoryId => {
                            const categoryProducts = products.filter(p => p.category_id === categoryId);
                            categoryProducts.sort((a, b) => a.product_title.localeCompare(b.product_title));
                            return categoryProducts.length > 0 ? (
                                <React.Fragment key={categoryId}>
                                    <tr className="bg-navy-900">
                                        <td colSpan="3" className="p-3 bg-golden-navy bg-opacity-90 text-white font-semibold text-center">
                                            {getCategoryName(categoryId)}
                                        </td>
                                    </tr>
                                    {categoryProducts.map(product => (
                                        <tr key={product.id} className="border-b hover:bg-gray-50">
                                            <td className="p-2">{product.product_code}</td>
                                            <td className="p-2 flex items-center">
                                                <img 
                                                    src={product.image ? `https://portal.goldengrouplinen.uk/public/${product.image}` : '/placeholder.jpg'} 
                                                    alt={product.product_title}
                                                    className="w-10 h-10 object-cover rounded-lg mr-2"
                                                />
                                                {product.product_title}
                                            </td>
                                            <td className="p-2 text-right">
                                                £{parseFloat(product.price || 0).toFixed(2)}
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ) : null;
                        })}
                    </tbody>
                </table>
            </div>
            
            <div className="fixed bottom-4 right-4 p-2 bg-white rounded-lg shadow-lg">
                <div className="bg-red-500 text-white h-5 w-5 rounded-lg flex items-center justify-center text-xs absolute -top-2 -right-2">
                    0
                </div>
            </div>
        </div>
    );
};

export default Pricing;