import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Switch } from 'antd';
import { HomeOutlined, BankOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FiShoppingCart, FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

const TableStyles = styled.div`
  .ReactTable {
    border: none;
    .rt-thead.-header {
      box-shadow: none;
      background: #f0f2f5;
      .rt-th {
        text-align: left;
        padding: 12px 15px;
        border-right: none;
        font-weight: bold;
        color: #1e3a8a;
      }
    }
    .rt-tbody .rt-tr-group {
      border-bottom: 1px solid #e5e7eb;
    }
    .rt-tbody .rt-td {
      border-right: none;
      padding: 8px 15px;
    }
    .rt-tbody .rt-tr:not(.-padRow):hover {
      background: #172554;
      color: #fbbf24;
    }
    .rt-tbody .rt-tr-group:nth-child(even) {
      background: #f8fafc;
    }
    .rt-tbody .rt-tr {
      &.in-cart {
        background: #172554;
        color: #fbbf24;
      }
    }
  }
`;

const StatusTag = styled.span`
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  background: ${props => props.completed ? '#dcfce7' : '#e0f2fe'};
  color: ${props => props.completed ? '#16a34a' : '#2563eb'};
`;

const StatusButton = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  background-color: ${props => props.active ? '#e0f2fe' : ''};
  color: ${props => props.active ? '#2563eb' : 'gray'};
  border: 1px solid ${props => props.active ? '#bae6fd' : '#bbf7d0'};
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;

  .icon {
    margin-right: 4px;
  }
`;

const CartButton = styled.div`
  position: relative;
  display: inline-block;

  .cart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #fbbf24;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    color: #1e3a8a;
    
    &:hover {
      opacity: 0.9;
    }

    .cart-icon {
      width: 24px;
      height: 24px;
    }
  }

  .indicator-wrapper {
    position: absolute;
    top: -4px;
    right: -4px;
    background: #fbbf24;
    border-radius: 50%;
    padding: 2px;
    
    .indicator-icon {
      width: 14px;
      height: 14px;
      color: #1e3a8a;
    }
  }
`;

const PropertyTable = ({ properties, cart, addToFromCart, removeFromCart, onToggleStatus }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'property_title',
      Cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={row.original.status == '1'}
            onChange={(checked) => onToggleStatus(row.original.id, checked ? '1' : '0')}
            size="small"
            style={{ marginRight: '10px' }}
          />
          <span>{row.original.property_title}</span>
        </div>
      ),
    },
    {
      Header: 'Address',
      accessor: 'apartment_address',
      Cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.original.property_type_id === 1 ? 
            <HomeOutlined style={{ marginRight: '10px', color: '#60a5fa' }} /> : 
            <BankOutlined style={{ marginRight: '10px', color: '#60a5fa' }} />
          }
          <span>{row.value}</span>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      Cell: row => (
        <StatusButton active={row.value == '1'}>
          <CheckCircleOutlined className="icon" />
          {row.value == '1'? 'Active' : 'Inactive'}
        </StatusButton>
      ),
    },
    {
      Header: 'Code',
      accessor: 'delivery_zip_code',
    },
    {
      Header: 'Add to Cart',
      Cell: row => {
        const isInCart = cart[row.original.id];
        return (
          <div className="flex justify-center">
            <CartButton>
              <button 
                className="cart-button"
                onClick={() => isInCart ? removeFromCart(0, row.original.id) : addToFromCart(row.original.id)}
              >
                <FiShoppingCart className="cart-icon" />
              </button>
              <div className="indicator-wrapper">
                {isInCart ? (
                  <FiMinusCircle className="indicator-icon" />
                ) : (
                  <FiPlusCircle className="indicator-icon" />
                )}
              </div>
            </CartButton>
          </div>
        );
      },
    },
  ];

  return (
    <TableStyles>
      <ReactTable
        data={properties}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              className: cart[rowInfo.original.id] ? 'in-cart' : '',
            }
          }
          return {};
        }}
        getPaginationProps={() => ({
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }
        })}
      />
    </TableStyles>
  );
};

export default PropertyTable;