import React, { useEffect } from 'react';
import DashboardCard from './DashboardCard';
import DashboardCardProperties from './DashboardCardProperties';
import DashboardCardOrders from './DashboardCardOrders';
import DashboardCardOrdersValue from './DashboardCardOrdersValue';
import YearlyOrdersChart from './YearlyOrdersChart';
import DashboardChart from './DashboardChart';
import { FaShoppingCart } from 'react-icons/fa';

const Dashboard = ({ properties, orders, cartItems }) => {
  const propertyStats = {
    active: properties.filter(p => p.status === '1').length,
    inactive: properties.filter(p => p.status !== '1').length
  };

  const orderStats = {
    active: orders?.orders?.filter(o => o.order_status === 'Scheduled' || o.order_status === 'On-Route').length || 0,
    completed: orders?.orders?.filter(o => o.order_status === 'Completed').length || 0,
    collected: orders?.orders?.filter(o => o.order_status === 'Collected').length || 0,
    failed: orders?.orders?.filter(o => o.order_status === 'Failed' || o.order_status === 'Cancelled').length || 0,
  };

  useEffect(() => {
  }, [properties, orders, cartItems]);

  return (
    orders?.orders && <div className="p-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-2">
        <DashboardCardProperties 
          title="My Properties"
          stats={propertyStats}
        />
        <DashboardCardOrders 
          title="Orders"
          orderStats={orderStats}
        />
        <DashboardCardOrdersValue 
          orders={orders?.orders || []}
        />
      </div>
      
{/*       <YearlyOrdersChart orders={orders?.orders || []} />
 */}      <DashboardChart orders={orders?.orders || []} />
    </div>
  );
};

export default Dashboard;