import React, { useState } from 'react';
import { FiEdit2, FiTrash2, FiShoppingCart, FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

const PropertyCard = ({ property, onDelete, cart, removeFromCart, addToFromCart, onEdit }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this property?')) {
      onDelete(property.idProperty);
    }
  };

  const isInCart = cart && cart[property.id];

  return (
    <div 
      className={`border rounded-md shadow property-card transition-all duration-300 relative overflow-hidden ${isHovered || isInCart ? 'bg-golden-navy' : 'bg-white'}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ height: '120px' }}
    >
      <div className="h-full flex flex-col p-2">
        {/* Top Action Bar */}
        <div className="flex justify-between items-start mb-1">
          {isHovered && (
            <FiEdit2 
              className="w-5 h-5 cursor-pointer hover:scale-110 transition-transform text-golden-yellow"
              onClick={() => onEdit(property.encrypted_id)} 
            />
          )}
          
          {/* Center Icon */}
          <div className="flex-1 flex justify-center">
            {!isHovered && (
              <img 
                src={property.property_type_id === 1 ? '/ico/propertyHouseIco.svg' : '/ico/propertyBuildingIco.svg'} 
                alt="Property Icon" 
                className="h-8 w-8"
              />
            )}

            {isHovered && (
              <div className="relative">
                <button 
                  onClick={() => isInCart ? removeFromCart(0, property.id) : addToFromCart(property.id)}
                  className="flex items-center justify-center bg-golden-yellow text-white rounded-full w-10 h-10 hover:bg-opacity-90 transition-colors duration-300"
                >
                  <FiShoppingCart className="w-6 h-6 text-golden-navy" />
                </button>
                {/* Overlay plus/minus icon */}
                <div className="absolute -top-1 -right-1 bg-golden-yellow rounded-full p-0.5">
                  {isInCart ? (
                    <FiMinusCircle className="w-3.5 h-3.5 text-golden-navy" />
                  ) : (
                    <FiPlusCircle className="w-3.5 h-3.5 text-golden-navy" />
                  )}
                </div>
              </div>
            )}
          </div>

          {isHovered && (
            <FiTrash2 
              className="w-5 h-5 cursor-pointer hover:scale-110 transition-transform text-golden-yellow"
              onClick={handleDelete} 
            />
          )}
        </div>

        {/* Property Info */}
        <div className="text-center flex-1 flex flex-col justify-center">
          <h3 className={`text-sm font-semibold truncate px-1 ${isHovered || isInCart ? 'text-golden-yellow' : 'text-golden-yellow'}`}>
            {property.property_title}
          </h3>
          <p className={`text-xs truncate px-1 mt-0.5 ${isHovered || isInCart ? 'text-gray-300' : 'text-gray-600'}`}>
            {property.apartment_address}
          </p>
          <div className={`text-xs mt-0.5 ${isHovered || isInCart ? 'text-white' : 'text-golden-navy'}`}>
            {property.delivery_zip_code}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;