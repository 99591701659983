import React, { useState, useEffect } from "react";
import {
  FiSearch,
  FiChevronDown,
  FiGrid,
  FiList,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import OrderCard from "./OrderCard";
import OrderTable from "./OrderTable";
import OrderDetails from "./OrderDetails";
import DateRangeSelector from '../DateRangeSelector';

const Orders = ({ ordersData, propertyData }) => {
  const FILTER_OPTIONS = [
    { value: "all", label: "All Orders" },
    { value: "scheduled", label: "Scheduled Orders" },
    { value: "completed", label: "Completed Orders" },
    { value: "failed", label: "Failed Orders" }  ];
  
  const SORT_OPTIONS = [
    { value: "dateAdded_desc", label: "Recently Added" },
    { value: "dateAdded_asc", label: "Oldest Added" },
    { value: "total_desc", label: "Price: High to Low" },
    { value: "total_asc", label: "Price: Low to High" },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("grid");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [groupedOrders, setGroupedOrders] = useState({});
  const [statusFilter, setStatusFilter] = useState("all");
  const [sortBy, setSortBy] = useState("dateAdded_desc");
  const [products, setProducts] = useState([]);
  const [packs, setPacks] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
    isActive: false
  });

  const handleDateRangeChange = (start, end) => {
    setDateRange({ 
      start, 
      end,
      isActive: true
    });
  };

  useEffect(() => {
    if (ordersData && ordersData.orders) {
      setFilteredOrders(ordersData.orders);
    }

    if (ordersData && ordersData.products) {
      setProducts(ordersData.products);
    }

    if (ordersData && ordersData.packs) {
      setPacks(ordersData.packs);
    }
  }, [ordersData]);

  useEffect(() => {
    if (ordersData && ordersData.orders) {
      filterAndGroupOrders(ordersData.orders);
    }
  }, [location.pathname, ordersData, searchTerm, dateRange, statusFilter, sortBy]);

  const getCurrentStatus = () => {
    const path = location.pathname.split("/").pop();
    switch (path) {
      case "active":
        return ["Scheduled", "On-Route"];
      case "completed":
        return ["Completed"];
      case "failed":
        return ["Failed", "Cancelled"];
      default:
        return ["Collected","Completed","Scheduled","Failed", "Cancelled", "On-Route"];
    }
  };

  const getPageTitle = () => {
    const path = location.pathname.split("/").pop();
    switch (path) {
      case "active":
        return "Active Orders";
      case "completed":
        return "Completed Orders";
      case "failed":
        return "Failed/Cancelled Orders";
      default:
        return "Orders";
    }
  };

  const sortOrders = (orders) => {
    return [...orders].sort((a, b) => {
      switch (sortBy) {
        case "dateAdded_desc":
          return new Date(b.created_at) - new Date(a.created_at);
        case "dateAdded_asc":
          return new Date(a.created_at) - new Date(b.created_at);
        case "total_desc":
          return parseFloat(b.order_total) - parseFloat(a.order_total);
        case "total_asc":
          return parseFloat(a.order_total) - parseFloat(b.order_total);
        default:
          return new Date(b.created_at) - new Date(a.created_at);
      }
    });
  };

  const filterAndGroupOrders = (orders) => {
    let filtered = orders.filter(order => {
      // Status filter
      const statusMatch = statusFilter === "all" ? 
        true :
        statusFilter === "scheduled" ? order.order_status === "Scheduled" || order.order_status === "On-Route" :
        statusFilter === "completed" ? order.order_status === "Completed" || order.order_status === "Collected" :
        statusFilter === "failed" ? order.order_status === "Failed" || order.order_status === "Cancelled" :
        true;

      // Search term filter
      const searchMatch = searchTerm === '' || 
        order.id.toString().includes(searchTerm.toLowerCase()) ||
        order.delivery_city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.apartment_number?.toLowerCase().includes(searchTerm.toLowerCase());

      // Date range filter
      const orderDate = new Date(order.property_date);
      const dateMatch = orderDate >= dateRange.start && orderDate <= dateRange.end;

      return statusMatch && searchMatch && dateMatch;
    });

    const sortedOrders = sortOrders(filtered);

    const grouped = sortedOrders.reduce((acc, order) => {
      const date = new Date(order.property_date);
      const dateStr = date.toLocaleDateString('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      
      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      acc[dateStr].push(order);
      return acc;
    }, {});

    setGroupedOrders(grouped);
    setFilteredOrders(filtered);
  };

  const renderGridView = () => (
    <div className="space-y-8">
      {Object.entries(groupedOrders).map(([date, orders]) => (
        <div key={date} className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-xl font-semibold text-golden-navy mb-4 border-b pb-2">
            {`${date} (${orders.length})`}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {orders.map((order) => (
              <OrderCard
                key={order.id}
                order={order}
                products={products}
                packs={packs}
                onViewDetails={() => setSelectedOrder(order)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  const renderListView = () => (
    <div className="space-y-8">
      {Object.entries(groupedOrders).map(([date, orders]) => (
        <div key={date} className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-xl font-semibold text-golden-navy mb-4 border-b pb-2">
            {`${date} (${orders.length})`}
          </h2>
          <OrderTable
            orders={orders}
            products={products}
            packs={packs}
            onViewDetails={setSelectedOrder}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="p-6 bg-golden-light">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-golden-navy flex items-center">
          <img src="/ico/orders.svg" alt="Orders" className="mr-2 h-6 w-6" />
          {getPageTitle()}
        </h1>
        <div className="flex space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search Order"
              className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-golden-blue focus:border-transparent"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FiSearch className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
          <DateRangeSelector onRangeChange={handleDateRangeChange} />
          <div className="relative">
            <select
              className="appearance-none bg-white px-4 py-2 pr-8 rounded-lg border focus:outline-none focus:ring-2 focus:ring-golden-blue"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              {FILTER_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <FiChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-golden-text" />
          </div>
       {/*    <div className="relative">
            <select
              className="appearance-none bg-white px-4 py-2 pr-8 rounded-lg border focus:outline-none focus:ring-2 focus:ring-golden-blue"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="" disabled>Sort By</option>
              {SORT_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <FiChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-golden-text" />
          </div> */}
          <div className="bg-white rounded-lg flex divide-x">
            <button
              className={`px-4 py-2 flex items-center space-x-2 transition-colors ${
                viewMode === "grid"
                  ? "text-golden-text bg-golden-light"
                  : "text-gray-500 hover:text-golden-text"
              }`}
              onClick={() => setViewMode("grid")}
            >
              <FiGrid className="text-lg" />
              <span className="text-sm">Grid</span>
            </button>
            <button
              className={`px-4 py-2 flex items-center space-x-2 transition-colors ${
                viewMode === "list"
                  ? "text-golden-text bg-golden-light"
                  : "text-gray-500 hover:text-golden-text"
              }`}
              onClick={() => setViewMode("list")}
            >
              <FiList className="text-lg" />
              <span className="text-sm">List</span>
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-6">
        <div
          className="col-span-4 space-y-4"
          style={{ maxHeight: "75vh", overflowY: "auto" }}
        >
          {Object.keys(groupedOrders).length === 0 ? (
            <div className="text-center text-gray-500">No orders found</div>
          ) : (
            viewMode === "grid" ? renderGridView() : renderListView()
          )}
        </div>
      </div>

      {selectedOrder && (
        <OrderDetails
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
          products={products}
          packs={packs}
        />
      )}
    </div>
  );
};

export default Orders;