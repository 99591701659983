// OrderCard.js
import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const STATUS_STYLES = {
  Scheduled: {
    bg: 'bg-blue-100',
    text: 'text-blue-800'
  },
  'On-Route': {
    bg: 'bg-indigo-100',
    text: 'text-indigo-800'
  },
  Completed: {
    bg: 'bg-green-100',
    text: 'text-green-800'
  },
  Collected: {
    bg: 'bg-green-100',
    text: 'text-black-800'
  },
  Cancelled: {
    bg: 'bg-red-100',
    text: 'text-red-800'
  },
  Failed: {
    bg: 'bg-rose-100',
    text: 'text-rose-800'
  }
};

const OrderCard = ({ order, products, packs, onViewDetails }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  const formatOrderId = (id) => {
    return `GGO-${id.toString().padStart(7, '0')}`;
  };

  const formatAddress = () => {
    const parts = [
      order.delivery_address,
      order.apartment_number && `Apt ${order.apartment_number}`,
      order.delivery_city,
      order.delivery_zip_code
    ].filter(Boolean);
    return parts.join(', ');
  };

  return (
    <div className="bg-white rounded-lg shadow-card overflow-hidden mb-4">
      <div className="bg-golden-navy text-white p-4">
        <div className="flex justify-between items-start mb-2">
          <div>
            <h3 className="text-xl font-semibold mb-1">{formatOrderId(order.id)}</h3>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-bold text-base">TOTAL: £{order.order_total}</span>
            <button 
              className="p-1 rounded-full hover:bg-white hover:bg-opacity-10 transition-colors"
              onClick={onViewDetails}
            >
              <FiEye className="text-lg text-yellow-400" />
            </button>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex items-start mb-2">
          <div className="bg-golden-blue bg-opacity-20 p-2 rounded-full mr-3">
            <img 
              src={order.property?.property_type_id === 1 ? '/ico/propertyHouseIco.svg' : '/ico/propertyBuildingIco.svg'} 
              alt="Property Type Icon" 
              className="inset-x-0 top-3 mx-auto h-12"
            />
          </div>
          <div className="flex-grow">
            <div className="flex justify-between items-start">
              <div>
                <p className="font-semibold text-lg text-golden-text">
                  {order.property_details[0]? order.property_details[0]?.property_title : 'Property Name'} 
                </p>
                <p className="text-sm opacity-80">{formatAddress()}</p>
              </div>
              <span className={`px-3 py-1 rounded-full text-xs ${
                STATUS_STYLES[order.order_status]?.bg || 'bg-gray-100'
              } ${STATUS_STYLES[order.order_status]?.text || 'text-gray-800'}`}>
                {order.order_status}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-sm text-golden-text opacity-70 mb-2">
          <span>Date: {order.property_date} <br/>Time: {order.property_details[0]?.arrival_time} - {order.property_details[0]?.completion_time}</span>
          <span>Items: {order.order_items}</span>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;