import apiService from '../utils/apiInterceptor';

const ordersService = {
  getAllOrders: async () => {
    return await apiService.get('/orders');
  },
  getAllOrdersNew: async () => {
    return await apiService.get('/ordesrnew');
  },
  getOrderById: async (id) => {
    return await apiService.get(`/orders/${id}`);
  },

  createOrder: async (orderData) => {
    return await apiService.post('/orders', orderData);
  },

  updateOrder: async (id, orderData) => {
    return await apiService.put(`/orders/${id}`, orderData);
  },

  deleteOrder: async (id) => {
    return await apiService.delete(`/orders/${id}`);
  }
};

export default ordersService;